<template>
  <div class="uello-app-map rounded h-100 position-relative mt-5">
    <transition name="fade" mode="out-in">
      <loading-overlay v-if="loading"></loading-overlay>
    </transition>
    <iframe
      id="metabaseIframe"
      :src="
        `https://metabase.uello.com.br/public/dashboard/a3828b34-d3c4-42c4-a3df-9e4491d8d9c1?data_relativa_%25C3%25A0_hoje=thismonth&parceiro_simplificado=${encodeURI(
          this.$store.state.auth.config.name
        )}&doc=${this.$store.state.auth.config.document}`
      "
      style="width:100%;height:100%;border:none"
    ></iframe>
  </div>
</template>

<script>
export default {
  name: 'Report',
};
</script>

<style lang="scss">
iframe
  > #root
  > div
  > div.flex.flex-column.flex-full.relative.scroll-y
  > div.EmbedFrame-header.flex.align-center.p1.sm-p2.lg-p3
  > div.flex.ml-auto
  > div
  > fieldset:nth-child(3) {
  display: none;
}
</style>
